// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery_page_gallery__iJWTX > div > div{
    background-color: var(--secondary_bg);
    margin-inline: 25px;
}

.gallery_page_gallery__iJWTX > div{
    padding-inline: 0px;
}

.gallery_page_gallery__iJWTX > div a img{
    width: 100%;
}

.gallery_page_gallery__iJWTX > div p{
    font-weight: 700;
    color: var(--secondary);
}

.gallery_page_gallery__iJWTX{
    height: 60vh;
}

.gallery_page_photo_gallery__dVbyL > div{
    max-width: 350px;
}

.gallery_page_photo_gallery__dVbyL > div p{
    font-size: 15px;
    font-weight: 600;
}


@media screen and (max-width: 600px){
    .gallery_page_gallery__iJWTX > div p{
        margin-left: 0px;
        font-size: 8px;
    }

    .gallery_page_gallery__iJWTX > div > div{
        margin-inline: 15px;
    }

    .gallery_page_gallery__iJWTX > div{
        padding-top: 0px;
    }
}


@media screen and (min-width: 600px){
    .gallery_page_gallery__iJWTX > div p{
        margin-left: 0px;
        font-size: 12px;
    }

    .gallery_page_gallery__iJWTX > div > div{
        margin-inline: 15px;
    }

    .gallery_page_gallery__iJWTX > div{
        padding-top: 0px;
    }
}


@media screen and (min-width: 768px){
    .gallery_page_gallery__iJWTX > div p{
        font-size: 14px;
    }
}


@media screen and (min-width: 1200px){
    .gallery_page_gallery__iJWTX > div p{
        font-size: 18px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/gallery_page.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,gBAAgB;QAChB,eAAe;IACnB;;IAEA;QACI,mBAAmB;IACvB;;IAEA;QACI,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ;;;AAGA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".gallery > div > div{\n    background-color: var(--secondary_bg);\n    margin-inline: 25px;\n}\n\n.gallery > div{\n    padding-inline: 0px;\n}\n\n.gallery > div a img{\n    width: 100%;\n}\n\n.gallery > div p{\n    font-weight: 700;\n    color: var(--secondary);\n}\n\n.gallery{\n    height: 60vh;\n}\n\n.photo_gallery > div{\n    max-width: 350px;\n}\n\n.photo_gallery > div p{\n    font-size: 15px;\n    font-weight: 600;\n}\n\n\n@media screen and (max-width: 600px){\n    .gallery > div p{\n        margin-left: 0px;\n        font-size: 8px;\n    }\n\n    .gallery > div > div{\n        margin-inline: 15px;\n    }\n\n    .gallery > div{\n        padding-top: 0px;\n    }\n}\n\n\n@media screen and (min-width: 600px){\n    .gallery > div p{\n        margin-left: 0px;\n        font-size: 12px;\n    }\n\n    .gallery > div > div{\n        margin-inline: 15px;\n    }\n\n    .gallery > div{\n        padding-top: 0px;\n    }\n}\n\n\n@media screen and (min-width: 768px){\n    .gallery > div p{\n        font-size: 14px;\n    }\n}\n\n\n@media screen and (min-width: 1200px){\n    .gallery > div p{\n        font-size: 18px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery": `gallery_page_gallery__iJWTX`,
	"photo_gallery": `gallery_page_photo_gallery__dVbyL`
};
export default ___CSS_LOADER_EXPORT___;
