import React, { useLayoutEffect } from 'react'
import styles from '../../styles/pages/gallery_page.module.css'
import SimpleCard from '../../components/SimpleCard';
import img from '../../assets/images/about_img.png'
import { Route, Routes } from 'react-router-dom';
import FuturePlans from '../FuturePlans';
import Photo_Gallery from './PhotoGallery';

function Gallery() {
    useLayoutEffect(() => {
      window.scrollTo(0,0);
  });
  const gallery = [
      {
        text:"GALLERY",
        img: img, 
        link: 'photo-gallery'
      },
      {
        text:"LATEST EVENTS",
        img: img, 
        link: 'latest-events'
      },
      {
        text:"VIDEO GALLERY",
        img: img, 
        link: 'video-gallery'
      },
  ];


  return (
    <div className='body'>
      <div className='heading'>
          GALLERY
      </div>
      <hr/>
      <hr/>

      <div className='body'>
          <div className={styles.gallery}>
            <SimpleCard list={gallery}></SimpleCard>
          </div>
      </div>
        
    </div>
  )
}

export default Gallery