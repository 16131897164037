import React from 'react'
import Button from '../components/Button'
import styles from '../styles/pages/aboutuspage.module.css'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import report from '../assets/2024 annual report.pdf'
import { useState } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

function display_pdf(){
    const pdf=document.querySelector(".pdf_display");
    

    if(pdf != null){
        if( pdf.style.display === "none"){
            pdf.style.display = "block";
        } else{
            pdf.style.display = "none";
        }
    }
    
}


function AnnualReport() {
    
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    
    function onLoadSuccess(numPages){
        setNumPages(numPages._pdfInfo.numPages);
    }

    const next = () => setPageNumber(pageNumber + 1);
    const prev = () => setPageNumber(pageNumber - 1);

  return (
    <div className='body'>
        <div className='heading'>
            ANNUAL REPORT
        </div>
        <hr/>
        <hr/>
        <div className={"body " + styles.report} id='2023'>
            <p>Annual Report 2023</p>
            <div className={styles.buttons}>
                <Button text="View" onClick = {display_pdf}/>
                <a href={report} download="report.pdf" > <Button text="Download"/></a>
            </div>
        </div>

        <div className='pdf_display' style={{display:"none"}}>
            
            <div className={styles.arrow_container}>
                <FaArrowAltCircleLeft  className={styles.arrow} onClick = {prev} text= "Prev"></FaArrowAltCircleLeft>
                <FaArrowAltCircleRight className={styles.arrow} onClick = {next}></FaArrowAltCircleRight>
            </div>
            <Document file={report} onLoadSuccess={onLoadSuccess} >
                <Page pageNumber={pageNumber} renderAnnotationLayer = {false} renderTextLayer={false}/>
            </Document>
            
            <p style={{textAlign: "center"}}>Page {pageNumber} of {numPages}</p>
        </div>
        

    </div>
  )
}

export default AnnualReport