import React, { useLayoutEffect } from 'react'
import styles from '../styles/pages/interest_rates_page.module.css'

function InterestRates() {
    useLayoutEffect(() => {
      window.scrollTo(0,0);
  });
  const rates = [
    {
      period : "30 days to 45 days",
      general : "6.00%",
      senior : "6.00%",
    },
    {
      period : "46 days to 90 days",
      general : "6.50%",
      senior : "6.50%",
    },
    {
      period : "91 days to 180 days",
      general : "7.25%",
      senior : "7.25%",
    },
    {
      period : "181 days to Below 1 year",
      general : "7.50%",
      senior : "7.50%",
    },
    {
      period : "1 Year & More than 1 Year ",
      general : "8.25%",
      senior : "8.75%",
    },
  ];

  return (
    <div className='body'>
      <div className='heading'>
          DEPOSIT INTEREST RATES
      </div>
      <hr/>
      <hr/>

      <div className='body'>
        <p className={styles.wef}>*w.e.f. 21.10.2022</p>
        <div className={styles.table}>
          <div className={`${styles.head} ${styles.row}`}>
            <p className={styles.col1}>DEPOSIT PERIOD</p>
            <p className={styles.col2}>GENERAL DEPOSITORS</p>
            <p className={styles.col3}>SENIOR CITIZENS</p>
          </div>

          {
            rates.map(item => {
              return <div className={styles.row}>
                        <p className={styles.col1}>{item.period}</p>
                        <p className={styles.col2}>{item.general}</p>
                        <p className={styles.col3}>{item.senior}</p>
                      </div>
            })
          }
        </div>
      </div>
        
    </div>
  )
}

export default InterestRates