import {React, useState, useEffect} from 'react'
import styles from '../styles/pages/homepage.css'
import gold_loan from '../assets/images/goldloan.png'
import vehicle_loan from '../assets/images/vehicleloan.png'
import home_loan from '../assets/images/homeloan.png'
import business_loan from '../assets/images/business_loan.png'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import { GradientCard } from '../components/GradientCard'
import { HiMiniBanknotes } from "react-icons/hi2";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdOutlineDoubleArrow } from "react-icons/md";
import about_img from '../assets/images/about_img.png'
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiDoubleQuotesL } from "react-icons/ri";
import about_us from '../assets/images/about_us.jpg';
import interest_rates from '../assets/images/interest_rates.webp';
import deposit from '../assets/images/deposits.webp'
import gallery from '../assets/images/gallery.png'
import Slider from '../components/Slider'
import SimpleCard from '../components/SimpleCard'
import Popup from 'reactjs-popup'


function HomePage() {
    const image_list=[
        {
            text:"Gold Loan",
            img:gold_loan,
            link:''
        },
        {
            text:"Vehicle Loan",
            img:vehicle_loan,
            link:''
        },
        {
            text:"Home Loan",
            img:home_loan,
            link:''
        },
        {
            text:"Business Loan",
            img:business_loan,
            link:''
        }
    ];

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    useEffect(() => {
        setTimeout(() => setOpen(true), 1000);
    }, []);

  return (
   
    <div className='container'>
        <div>
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <p>THE TALIKOTI SAHAKARI BANK NIYAMIT</p>
          <div className='content'>
            <p>As per Reserve Bank of India Circular dated 01.12.2022, we hereby declare our Bank as </p>
            
            <p>"Financially Sound and Well Managed (FSWM) Bank"</p>
            <p>For FY 2023-24</p>
          </div>
          
        </div>
      </Popup>
    </div>
        <div className='intro'>
            <RiDoubleQuotesL/>
            <div className='intro_grid'>
                <div className='grid_two'>
                    <p>About Us:</p>
                    <p>The Talikoti Sahakari Bank Niyamit was established on basic principle of Co-Operative slogan
                        "One for all - All for one "in the year 1960 by a few likeminded business men's of Talikoti.</p>

                    <p>This was also to encourage banking needs of lower and middle class people of savings habit
                    and to create funds for providing financial aid facilities to deserving members and to freed
                    from the clutches of private money lenders thrown open to all communities.</p>

                    <p>The Bank began with share capital of Rs.2000 with co-operative principle "Self-help through
                    Mutual help".</p>
                </div>
                <div><Link to="/about"><img src={ about_us }/><p className='subtext'>About Us</p></Link></div>
                <div><Link to="/deposits/savings-account"><img src={ deposit }/><p className='subtext'>Deposits</p></Link></div>
                <div><Link to="/deposit-interest-rates"><img src={ interest_rates }/><p className='subtext'>Interest Rates</p></Link></div>
                <div><Link to="/gallery"><img src={ gallery }/><p className='subtext' >Gallary</p></Link></div>
            </div>
        </div>

        <div className='break'/>

        <div className='section news_container'>
        <p className='subtitle'>LATEST NEWS</p>
            <div className='news'>
                <div className='items'>
                    <div>
                        <MdOutlineDoubleArrow/>
                        <span>LATEST NEWS</span>
                    </div>
                    <div>
                        <MdOutlineDoubleArrow/>
                        <span>LATEST NEWS</span>
                    </div>
                    <div>
                        <MdOutlineDoubleArrow/>
                        <span>LATEST NEWS</span>
                    </div>
                    
                    
                </div>
            </div>
        </div>

        <div className='break'/>
        
        <div className='section loans'>
        <p className='subtitle'>LOANS</p>
            <div>
                <SimpleCard list ={image_list}/>
            </div>
            <Button text = "View More"/>
        </div>

        <div className='break'/>
        <div className='break'/>

        <div className='section'>
            <p className='subtitle'>FACILITIES</p>  
                    <div className='facilities'>
                    <Slider/>
            </div>
        </div>
        

        <div className='about'>
            <p className='subtitle hello'>WELCOME TO THE TALIKOTI SAHAKARI BANK NIYAMIT!</p>
            <p className='content'>
            <img src={ about_img }/>
                Established on 28/03/1960 and licensed by Reserve Bank of India to carry on 
                banking business The Talikoti Sahakari Bank Niyamit, Talikoti is today reckoned as one of the 
                top-notch & leading amongst the Urban Co-operative banks operating in the region of North Karnataka. 
                In the six decades of its existence, the performance of bank has been prototypical in terms of 
                growth in deposits, advances & overall operational preeminent. 
                <br/> <br/>
                With a view to extend banking 
                services to the lower and middle class people of Talikoti and its surrounding villages, The Talikoti 
                Sahakari Bank is providing devised need based loan schemes, keeping in view every imaginable need of 
                an individual borrower and business holders.
                
                </p>
                
        </div>

        <div className='break'/>

        <div className='section branches'>
            <FaMapMarkerAlt/>
            <p>Want to visit us in person? <br/><span><Link to='/branches-and-bank-timings'>Check out all of our branches --{">"}</Link></span></p>
            
        </div>
        <div className='break'/>
    </div>
    
    
  )
}

export default HomePage